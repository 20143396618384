import { getFrontendVersion } from "utils/env";
import VersionDisplay from "components/VersionDisplay";
import useBackendVersion from "./use-backend-version";

const Versions = () => {
  const backendVersionQuery = useBackendVersion();

  if (backendVersionQuery.isLoading) {
    return null;
  }

  let backendVersion = "unknown";
  if (backendVersionQuery.isSuccess && backendVersionQuery.data) {
    backendVersion = backendVersionQuery.data;
  }

  const frontendVersion = getFrontendVersion();

  return (
    <VersionDisplay
      frontendVersion={frontendVersion}
      backendVersion={backendVersion}
    />
  );
};

export default Versions;
