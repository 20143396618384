import { Fragment, useMemo } from "react";
import clsx from "clsx";
import {
  Disclosure,
  Menu,
  Popover,
  Transition,
} from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link, NavLink, useNavigate } from "react-router-dom";

import useAuth from "hooks/use-auth";
import { FlatNavItem, NavItem } from "./navigation-types";
import { filterNavigation } from "./navigation-utils";

const getNavigation = (isUserLoggedIn: boolean) => {
  return [
    { name: "Dashboard", to: "/" },
    { name: "Demo", to: "/demo" },
    { name: "Hidden Page", to: "/hidden", hidden: true },
    { name: "Campaigns", to: "/campaigns", hidden: !isUserLoggedIn },
    { name: "Administration",
      hidden: !isUserLoggedIn,
      subnavigation: [
        { name: "Spaces", to: "/admin/spaces" },
        { name: "Users", to: "/admin/users" },
        { name: "Platforms", to: "/admin/platforms" },
        { name: "Hidden Admin Page", to: "/admin/hidden", hidden: true },
      ] },
  ];
};

const getUserNavigation = (isUserLoggedIn: boolean) => {
  return [
    { name: "My Profile", to: "/me", hidden: !isUserLoggedIn },
    { name: "Settings", to: "/me/settings", hidden: !isUserLoggedIn },
    { name: "Sign in", to: "/login", hidden: isUserLoggedIn },
  ];
};

const getNavLinkClasses = (isMobile: boolean, isActive = false) => {
  const mobileBaseClasses = "flex justify-between w-full rounded-md px-3 py-2 text-base font-medium text-white";
  const desktopBaseClasses = "rounded-md px-3 py-2 text-sm font-medium text-white";
  const activeStateClasses = "bg-primary-700";
  const inactiveStateClasses = "hover:bg-primary-500 hover:bg-opacity-75";

  return clsx(
    isMobile ? mobileBaseClasses : desktopBaseClasses,
    isActive ? activeStateClasses : inactiveStateClasses
  );
};

const getUserNavLinkClasses = (isMobile: boolean, isActive = false) => {
  const mobileClasses = "block w-full text-left rounded-md px-3 py-2 text-base font-medium text-white hover:bg-primary-500 hover:bg-opacity-75";
  const desktopClasses = "block w-full text-left px-4 py-2 text-sm text-gray-700";
  const desktopActiveClasses = "bg-gray-100";

  return clsx(
    isMobile ? mobileClasses : desktopClasses,
    isActive && !isMobile ? desktopActiveClasses : ""
  );
};

const DesktopNavItem = ({ item }: { item: NavItem }) => {
  if ("subnavigation" in item) {
    return (
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button className={clsx(
              getNavLinkClasses(false),
              open && "bg-primary-500 bg-opacity-75",
              "flex",
            )}>
              <span>{item.name}</span>
              <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-min -translate-x-1/2 px-4">
                <div className="w-56 shrink rounded-xl bg-white p-4 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
                  {item.subnavigation.map((subnavItem) => (
                    <Popover.Button
                      as={NavLink}
                      key={subnavItem.name}
                      to={subnavItem.to}
                      className="block p-2 hover:text-primary-600"
                    >
                      {subnavItem.name}
                    </Popover.Button>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  }

  return (
    <NavLink
      to={item.to}
      className={({ isActive }) => getNavLinkClasses(false, isActive)}
    >
      {item.name}
    </NavLink>
  );
};

const MobileNavItem = ({ item }: { item: NavItem }) => {
  if ("subnavigation" in item) {
    return (
      <Disclosure>
        <Disclosure.Button
          className={getNavLinkClasses(true)}
        >
          <span>{item.name}</span>
          <ChevronDownIcon
            className="text-gray-300 group-hover:text-white ml-2 h-5 w-5 -mr-1"
            aria-hidden="true"
          />
        </Disclosure.Button>
        <Disclosure.Panel>
          <div className="relative grid gap-2 p-2">
            {item.subnavigation.map((subnavItem) => (
              <NavLink
                key={subnavItem.name}
                to={subnavItem.to}
                className={({ isActive }) => getNavLinkClasses(true, isActive)}
              >
                {subnavItem.name}
              </NavLink>
            ))}
          </div>
        </Disclosure.Panel>
      </Disclosure>
    );
  }

  return (
    <Disclosure.Button
      as={NavLink}
      to={item.to}
      className={({ isActive }: { isActive: boolean }) => getNavLinkClasses(true, isActive)}
    >
      {item.name}
    </Disclosure.Button>
  );
};

const NavBar = () => {
  const navigate = useNavigate();
  const {
    currentUser,
    isLoggedIn,
    ...auth
  } = useAuth();

  const handleSignOut = () => {
    auth.signOut();

    navigate("/");
  };

  const navigation = useMemo(() => {
    return filterNavigation(getNavigation(isLoggedIn));
  }, [isLoggedIn]);

  const userNavigation = useMemo(() => {
    return filterNavigation(getUserNavigation(isLoggedIn)) as FlatNavItem[];
  }, [isLoggedIn]);

  const navUserImage = isLoggedIn ? currentUser.imageUrl : "https://placehold.co/80x80?text=Login";

  return (
    <Disclosure as="nav" className="bg-primary-600">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 items-center justify-between">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <Link to="/">
                    <img
                      className="h-8 w-8"
                      src="/logo512.png"
                      alt="Bannertank"
                    />
                  </Link>
                </div>
                <div className="hidden md:block">
                  <div className="ml-10 flex items-baseline space-x-4">
                    {navigation.map((item) => <DesktopNavItem key={item.name} item={item} />)}
                  </div>
                </div>
              </div>
              <div className="hidden md:block">
                <div className="ml-4 flex items-center md:ml-6">
                  <button
                    type="button"
                    className="
                      relative rounded-full p-1 bg-primary-600 text-primary-200
                      hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-primary-600
                  ">
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button>

                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-3">
                    <div>
                      <Menu.Button className="
                        relative flex max-w-xs items-center rounded-full bg-primary-600 text-sm
                        focus:outline-none
                        focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-primary-600
                      ">
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-8 w-8 rounded-full"
                          src={navUserImage}
                          alt=""
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="
                        absolute right-0 z-10 mt-2 w-48 py-1 origin-top-right rounded-md bg-white shadow-lg
                        ring-1 ring-black ring-opacity-5 focus:outline-none
                      ">
                        {userNavigation.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <NavLink
                                to={item.to}
                                className={getUserNavLinkClasses(false, active)}
                              >
                                {item.name}
                              </NavLink>
                            )}
                          </Menu.Item>
                        ))}

                        { isLoggedIn && (
                          <Menu.Item key="Sign out">
                            {({ active }) => (
                              <button
                                className={getUserNavLinkClasses(false, active)}
                                onClick={handleSignOut}
                              >
                                Sign out
                              </button>
                            )}
                          </Menu.Item>
                        )}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
              <div className="-mr-2 flex md:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="
                  relative inline-flex items-center justify-center
                  rounded-md bg-primary-600 p-2 text-primary-200
                  hover:bg-primary-500 hover:bg-opacity-75 hover:text-white
                  focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-primary-600
                ">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon
                      className="block h-6 w-6"
                      aria-hidden="true"
                    />
                  ) : (
                    <Bars3Icon
                      className="block h-6 w-6"
                      aria-hidden="true"
                    />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
              {navigation.map((item) => <MobileNavItem key={item.name} item={item} />)}
            </div>
            <div className="border-t border-primary-700 pb-3 pt-4">
              <div className="flex items-center px-5">
                <div className="flex-shrink-0">
                  <img
                    className="h-10 w-10 rounded-full"
                    src={navUserImage}
                    alt=""
                  />
                </div>

                { isLoggedIn && (
                  <div className="ml-3">
                    <div className="text-base font-medium text-white">
                      {currentUser.name}
                    </div>
                    <div className="text-sm font-medium text-primary-300">
                      {currentUser.email}
                    </div>
                  </div>
                )}

                <button
                  type="button"
                  className="
                    relative ml-auto flex-shrink-0 rounded-full bg-primary-600 p-1 text-primary-200
                  hover:text-white focus:outline-none
                  focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-primary-600
                ">
                  <span className="absolute -inset-1.5" />
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-3 space-y-1 px-2">
                {userNavigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as={NavLink}
                    to={item.to}
                    className={getUserNavLinkClasses(true)}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}

                { isLoggedIn && (
                  <Disclosure.Button
                    key="Sign out"
                    onClick={handleSignOut}
                    className={getUserNavLinkClasses(true)}
                  >
                    Sign out
                  </Disclosure.Button>
                )}
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default NavBar;
