import { useMatches } from "react-router-dom";

import { Handle } from "types/navigation";
import Breadcrumbs from "./Breadcrumbs";

const NavHeader = () => {
  const matches = useMatches();
  const crumbs = matches
    .filter((match) => match.handle && (match.handle as Handle).crumb)
    .map((match) => (match.handle as Handle).crumb);

  if (crumbs.length === 0) {
    return null;
  }

  return (
    <header className="bg-white shadow-sm">
      <div className="mx-auto max-w-7xl px-4 py-4 sm:px-6 lg:px-8">
        <Breadcrumbs crumbs={crumbs} />
      </div>
    </header>
  );
};

export default NavHeader;
