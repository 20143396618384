import axios, { AxiosInstance } from "axios";

import { getBackendBaseUrl } from "utils/env";

let instance: AxiosInstance;

/**
 * Create a new Axios instance with the default configuration.
 */
const createClientInstance = (): AxiosInstance => {
  instance = axios.create({
    baseURL: getBackendBaseUrl(),
  });

  instance.defaults.headers.common.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return instance;
};

/**
 * Set the Authorization header for the default Axios instance.
 *
 * @param token The token to set.
 */
const setAuthorizationHeader = (token: string) => {
  instance.defaults.headers.common.Authorization = `Bearer ${token}`;
};

/**
 * Clear the Authorization header for the default Axios instance.
 */
const clearAuthorizationHeader = () => {
  delete instance.defaults.headers.common.Authorization;
};

export default createClientInstance();
export {
  clearAuthorizationHeader,
  setAuthorizationHeader,
};
