import { FlatNavItem, NavItem } from "./navigation-types";

/**
 * Filters the navigation items to remove any hidden items, including hidden subnavigation items.
 *
 * If a top level item has no visible children, it will be removed, too.
 */
const filterNavigation = (navigation: NavItem[]): NavItem[] => {
  const filteredNavigation: NavItem[] = [];

  navigation.forEach((item) => {
    if (item.hidden) {
      return;
    }

    if ("subnavigation" in item) {
      const subnavItems = item.subnavigation.filter((subnavItem: FlatNavItem) => !subnavItem.hidden);

      if (subnavItems.length === 0) {
        return;
      }

      item.subnavigation = subnavItems;

      filteredNavigation.push(item);
      return;
    }

    filteredNavigation.push(item);
  });

  return filteredNavigation;
};

export {
  filterNavigation,
};
