import { Outlet } from "react-router-dom";

import Versions from "features/meta/Versions";

export default function AuthLayout() {
  return (
    <>
      <div className="flex flex-col justify-center min-h-screen mx-auto px-4 sm:px-6 lg:px-8">
        <div className="mx-auto w-full sm:max-w-md">
          <Outlet />
        </div>
      </div>

      <div className="absolute bottom-0 right-0 py-2 px-6 lg:px-8 xl:px-2">
        <Versions />
      </div>
    </>
  );
}

AuthLayout.propTypes = {};

AuthLayout.defaultProps = {};
