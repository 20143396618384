import { Outlet } from "react-router-dom";

import NavBar from "features/navigation/NavBar";
import NavHeader from "features/navigation/NavHeader";
import Versions from "features/meta/Versions";

export default function AppLayout() {
  return (
    <>
      <div>
        <NavBar />
        <NavHeader />

        <main>
          <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
            <Outlet />
          </div>
        </main>
      </div>

      <div className="absolute bottom-0 right-0 py-2 px-6 lg:px-8 xl:px-2">
        <Versions />
      </div>
    </>
  );
}

AppLayout.propTypes = {};

AppLayout.defaultProps = {};
