import {
  createRoutesFromElements,
  Navigate,
  Route,
} from "react-router-dom";

import RequireAuth from "features/auth/require-auth";
import AppLayout from "layouts/AppLayout";
import AuthLayout from "layouts/AuthLayout";

const appRoutes = createRoutesFromElements(
  <>
    <Route path="" element={<AuthLayout />}>
      <Route
        path="login"
        lazy={async() => import("pages/LoginPage")}
      />
    </Route>

    <Route path="" element={
      <RequireAuth>
        <AppLayout />
      </RequireAuth>
    }>
      <Route
        index
        lazy={async() => import("pages/HomePage")}
        handle={{ crumb: { name: "Home", to: "/" } }}
      />

      <Route
        path="demo"
        lazy={async() => import("pages/DemoPage")}
        handle={{ crumb: { name: "Demo", to: "/demo" } }}
      />

      <Route path="campaigns" handle={{ crumb: { name: "Campaigns", to: "/campaigns" } }}>
        <Route index lazy={async() => import("pages/CampaignsPage")} />
        <Route path=":campaignId" lazy={async() => import("pages/CampaignPage")} />
      </Route>

      <Route path="admin" handle={{ crumb: { name: "Administration", to: "/admin" } }}>
        <Route index element={<Navigate to="/admin/spaces" />} />

        <Route path="spaces" handle={{ crumb: { name: "Spaces", to: "/admin/spaces" } }}>
          <Route index lazy={async() => import("pages/AdminSpacesPage")} />
          <Route path=":spaceId" lazy={async() => import("pages/AdminSpacePage")} />
        </Route>

        <Route
          path="users"
          lazy={async() => import("pages/AdminUsersPage")}
          handle={{ crumb: { name: "Users", to: "/admin/users" } }}
        />

        <Route
          path="platforms"
          lazy={async() => import("pages/AdminPlatformsPage")}
          handle={{ crumb: { name: "Platforms", to: "/admin/platforms" } }}
        />
      </Route>

      <Route path="me" handle={{ crumb: { name: "Me", to: "/me" } }}>
        <Route index lazy={async() => import("pages/MePage")} />
        <Route
          path="settings"
          lazy={async() => import("pages/MySettingsPage")}
          handle={{ crumb: { name: "Settings", to: "/me/settings" } }}
        />
      </Route>
    </Route>

    <Route path="*" element={<Navigate to="/" />} />
  </>
);

export default appRoutes;
