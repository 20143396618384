/**
 * Returns the base URL of the backend API.
 */
const getBackendBaseUrl = (): string => {
  const defaultUrl = "http://localhost:3333";
  const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;

  return baseUrl || defaultUrl;
};

/**
 * Reads the version number of the frontend app from the environment variables.
 */
const getFrontendVersion = (): string => {
  const version = process.env.REACT_APP_VERSION;

  return version || "unknown";
};

export {
  getBackendBaseUrl,
  getFrontendVersion,
};
