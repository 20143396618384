import { StateCreator } from "zustand";
import { persist } from "zustand/middleware";

import { AuthStoreSlice } from "types/auth";

const createAuthStoreSlice: StateCreator<AuthStoreSlice, [], [["zustand/persist", AuthStoreSlice]]> = persist((set) => ({
  credentials: null,
  currentUser: null,
  signin: (user, token, expiresAt) => set({
    credentials: {
      token,
      expiresAt,
    },
    currentUser: user,
  }),
  signout: () => set({
    credentials: null,
    currentUser: null,
  }),
}), {
  name: "auth",
});

export default createAuthStoreSlice;
export type { AuthStoreSlice };
