import { useEffect, useState } from "react";
import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import * as apiClient from "services/api/client";
import * as authService from "services/api/auth";
import useStore from "store";
import { LoggedInUser, LoginData } from "types/auth";

const defaultUser = {
  id: "0",
  name: "DEFAULT",
  email: "nomail",
  imageUrl: "",
};

const useProvideAuth = () => {
  const queryClient = useQueryClient();

  const [isInitilized, setIsInitilized] = useState(false);

  const store = useStore((s) => ({
    credentials: s.credentials,
    currentUser: s.currentUser,
    signin: s.signin,
    signout: s.signout,
  }));

  const isLoggedIn = store.currentUser !== null;
  const currentUser: LoggedInUser = store.currentUser || defaultUser;
  const credentials = isLoggedIn ? store.credentials : undefined;

  const signinMutation = useMutation({
    mutationFn: async({ email, password }: LoginData) => authService.login(email, password),
    onSuccess: ({ data, headers }) => {
      const loggedInUser = {
        id: data.id,
        name: data.name,
        email: data.email,
        imageUrl: data.imageUrl,
      };

      store.signin(loggedInUser, headers["api-token"], headers["api-token-expiresat"]);
    },
  });

  const signIn = async(email: string, password: string) => {
    signinMutation.mutate({
      email,
      password,
    });
  };

  const signOut = async() => {
    await authService.logout(currentUser.id);
    store.signout();
    queryClient.invalidateQueries();
  };

  useEffect(() => {
    if (!credentials) {
      apiClient.clearAuthorizationHeader();
      return;
    }

    apiClient.setAuthorizationHeader(credentials.token);
  }, [credentials]);

  useEffect(() => {
    setIsInitilized(true);
  }, []);

  return {
    currentUser,
    error: signinMutation.error as AxiosError | null,
    isInitilized,
    isLoggedIn,
    isPending: signinMutation.isPending,
    signIn,
    signOut,
  };
};

export default useProvideAuth;
