type VersionDisplayProps = {
  frontendVersion: string;
  backendVersion: string;
};

export default function VersionDisplay({ frontendVersion, backendVersion }: VersionDisplayProps) {
  return (
    <div className="text-xs font-light text-gray-400">
      <span>FE {frontendVersion} | BE {backendVersion}</span>
    </div>
  );
}

VersionDisplay.defaultProps = {
  backendVersion: "",
  frontendVersion: "",
};
