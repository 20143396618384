import {
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import appRoutes from "routes/app-routes";
import ProvideAuth from "features/auth/provide-auth";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60000,
    },
  },
});

const appRouter = createBrowserRouter(appRoutes);

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ProvideAuth>
        <RouterProvider router={appRouter} />
      </ProvideAuth>

      <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
    </QueryClientProvider>
  );
};

export default App;
