import client from "./client";

/**
 * Fetches the backend app's version number
 */
const fetchVersion = async(): Promise<string | null> => {
  const result = await client.get("/version");
  return result.data.version;
};

export {
  fetchVersion as backendVersion,
};
