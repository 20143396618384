import { useQuery } from "@tanstack/react-query";
import { backendVersion } from "services/api/meta";

const useBackendVersion = () => {
  const result = useQuery({
    queryKey: ["backend-version"],
    queryFn: backendVersion,
    staleTime: Infinity,
  });

  return result;
};

export default useBackendVersion;
