import { PropsWithChildren } from "react";

import { authContext } from "./auth-context";
import useProvideAuth from "./use-provide-auth";

function ProvideAuth({ children }: PropsWithChildren) {
  const auth = useProvideAuth();

  if (!auth.isInitilized) {
    return null;
  }

  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}

export default ProvideAuth;
