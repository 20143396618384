import client from "./client";

/**
 * Logs in a user
 */
const login = async(email: string, password: string) => {
  const result = await client.post("/auth", {
    email,
    password,
  });

  return {
    data: result.data,
    headers: result.headers,
  };
};

/**
 * Logs out a user
 */
const logout = async(userId: string) => {
  const result = await client.delete(`/auth/${userId}`);
  return result.data;
};

export {
  login,
  logout,
};
